import styled from 'styled-components'

import { useSvgHref } from '../../../svgHrefContext'

import * as illustration from '../../Illustration'

const SYMBOL_ID = 'illustration-criteria-zero-waste-def'

FullZeroWaste.filename = SYMBOL_ID
export function FullZeroWaste(
    props: illustration.SpecificIllustrationProps
): React.ReactElement {
    return (
        <illustration.Illustration {...props} name="zero-waste" viewBox="0 -46 184 289">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M57.5 2.9c-2.2 1-4.8 2.8-5.9 3.9-2 2.3-3.2 9.6-2.2 13.6.8 3.1 2.6 3.5 2.6.6 0-2.4 5.3-8.6 9.1-10.6C63.3 9.3 69.2 9 90.7 9c14.7 0 28.5.5 30.7.9 4.6 1.1 10.4 6.4 11.4 10.6 1.5 5.5 6.3 7.2 22 7.7 15.4.5 20.2 2 20.2 6.2 0 1.7-.9 2.7-3.2 3.7-4.2 1.8-11.4 1.8-35.8 0-14.8-1.1-30.1-1.3-64-.8-24.5.4-48.1 1-52.5 1.3-9.7.6-11.4-.1-9.5-4.5 1.2-3 5.4-5.1 10.4-5.1 2.2 0 24.2-4 26.6-4.8 3.3-1.1-7.3-2.5-20.5-2.6-12-.1-14.6.2-17.9 1.8-4.8 2.4-7.5 8-7.6 15.5 0 9.6 6 15.4 17.4 16.7l5.7.7-.2 13.1c-.1 12.3 4.2 64.1 7.6 91.6.7 6.3 1.7 15.3 2.1 20 2 25.1 8.7 29.2 43 26 17.9-1.7 18.5-2.2 5.8-4.9-7-1.5-14.7-2.4-21.5-2.5-13.2-.3-15.2-1.3-17.3-8.9-.8-2.8-1.8-9-2.1-13.7-.4-4.7-1.3-13.2-2-19-.8-5.8-2.8-26.5-4.5-46-2.6-29.2-3.6-37.4-5.9-45.9l-2.9-10.3 63.2.3c34.7.1 65.2-.1 67.7-.5l4.7-.8-1.9 5.8c-3.3 9.9-3.8 16.6-4.9 60.9-1.2 44.4-2.1 54.3-6 64.8-1.1 3-2.9 6.2-3.8 7.1-2.4 2-11.3 5.1-17.7 6-2.7.4-8.1 1.3-12 2.1-3.8.7-9.2 1.6-12 2l-5 .8 5 1.4c8.5 2.3 23.1 2.7 30.8.9 8.7-2 16-5.8 18.7-9.6 2.9-4.1 5.9-12.8 7.6-22 1.8-10 3.8-75.1 3-100.6l-.5-20.1 4.2-1.4 4.2-1.3-8.5-.7c-4.7-.4-14.1-1.2-21-1.8-7-.6-37.2-1.2-69-1.4-50.9-.2-56.8-.4-59.6-1.9-1.7-1-3.4-2.4-3.7-3.2-.4-1.1.6-.9 4.1.7 4.6 2.2 5.3 2.2 63.7 2.2 41.6.1 62.1.5 69.5 1.4 12.7 1.5 17.3.9 23.5-3l4.8-3-.6 3.9-.5 4 2.8-1c4.9-1.6 6.5-4.4 6.4-10.7 0-7.6-2.2-11.3-8.1-14.2-4.2-2.1-6.6-2.4-18.8-2.7-10.8-.3-14.9-.1-17.6 1.1l-3.5 1.4.4-5.3c.6-7.5-1-10.8-6.7-13.6-4.5-2.2-5.6-2.3-36.1-2.6-29-.2-31.8-.1-35.5 1.7z" />
                    <path d="M67.6 10.9c-7.3 1.1-9.2 4.9-6.1 12.3 2.3 5.6 4.4 6 30.7 5.7 19.8-.2 23.8-.6 26.9-2.1 3.9-1.8 4.9-3.4 4.9-7.7 0-3.9-2.6-6.9-6.3-7.5-5.7-.9-45-1.4-50.1-.7zm48.1 8.6c2.3.4 4.4 1.2 4.7 2.1.5 1.2.1 1.2-2.6-.2s-6-1.5-26.7-.9c-17.7.5-24.2 1.1-25.8 2.1-2.5 1.6-3 .7-1-1.9.9-1.2 3.1-1.7 8.2-1.9 9.8-.4 38.2.1 43.2.7zM130.8 85.7c-2.1 2.1-3.5 5.8-5.8 14.8-1.8 6.6-4.7 16.7-6.6 22.5-2.7 8.6-3.2 11.4-2.6 14.9l.7 4.4 3.2-2.7c3.5-3 6.9-12.3 13.3-36.7 2.7-10.4 2.9-11.9 1.7-15.8l-1.2-4.2-2.7 2.8zM68.5 87.6c-7.1 1.7-10.6 5.4-14.1 15-2.6 7.2-2.6 31.5-.1 39.9 3.2 10.4 11.8 16.6 20.5 14.9 11.8-2.4 23.3-26.6 20.5-42.9-.6-3.3-2-8-3-10.4l-1.9-4.4-2.1 14.9c-2.4 17-5 25-10 30.5-4.6 5.1-8.9 5.4-12.7.9-3.8-4.5-5-10.2-5-23 0-22.6 5-30 20.3-30 4.2 0 5.7.5 7.6 2.5 2.6 2.5 3.3 1.8 1.4-1.7-2-3.8-15.2-7.6-21.4-6.2zM110.2 93.3c-4.2 4.5-1.9 10.1 4.1 10.1 3.8 0 7.2-2.3 8-5.4.4-1.5-.6-2.6-3.8-4.5-5.3-3.1-5.6-3.1-8.3-.2zM135.6 115.5c-4.3 6.6 4.8 13 10.2 7.2 3.2-3.4 2.7-5.2-1.8-7.6-5.2-2.7-6.4-2.6-8.4.4z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </illustration.Illustration>
    )
}

export const ZeroWaste = styled(function ZeroWaste(
    props: illustration.SpecificIllustrationProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <illustration.Illustration {...props} name="zero-waste" viewBox="0 -46 184 289">
            <use href={href} />
        </illustration.Illustration>
    )
})``
